<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
        <vxe-form-item title="类型" span="8">
          <vxe-select v-model="searchData.type" placeholder="请选择类型" style="width: 100%">
            <vxe-option v-for="item in transType" :key="item.value" :label="item.label" :value="item.value"></vxe-option>
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:operation="{ row }">
          <vxe-button type="text" status="primary" @click="goPage('edit', row)">充值</vxe-button>
          <vxe-button type="text" status="primary" @click="goTransList(row)">充值流水</vxe-button>
          <vxe-button type="text" status="primary" @click="remove(row.id)">退卡</vxe-button>
        </template>
      </vxe-grid>
    </div>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      ></vxe-pager>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      transType: [
        {
          value: '101',
          label: '人工(现金)充值',
        },
        {
          value: '102',
          label: '微信充值',
        },
         {
          value: '103',
          label: '支付宝充值',
        },
         {
          value: '201',
          label: '人工退费',
        },
         {
          value: '202',
          label: '饭卡机刷卡消费',
        },
         {
          value: '203',
          label: '退卡退钱',
        },
      ],
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 80,
          align: 'center',
        },
        {
          field: 'flowNo',
          title: '流水号',
          showOverflow: true,
          align: 'center',
          minWidth: '150',
        },
        {
          field: 'amont',
          title: '交易金额单位(元)',
          showOverflow: true,
          align: 'center',
          minWidth: '60',
        },
        {
          field: 'transTypeName',
          title: '交易类型',
          showOverflow: true,
          align: 'center',
          minWidth: '80',
        },
        {
          field: 'transTime',
          title: '交易时间',
          showOverflow: true,
          align: 'center',
          minWidth: '100',
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId']),
  },
  watch: {
    seqId() {
      // this.getLists()
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['getMealCardList', 'addMealCard', 'searchPerson', 'destoryCard', 'addTransCard', 'cardTransList']),
    initView() {
      this.getLists()
    },

    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    //充值流水
    getLists() {
      this.loading = true
      this.searchData.id = this.$route.params.id 
      this.cardTransList({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          ...this.searchData,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records.map((item) => {
            return {
              ...item,
              cardStatus: item.cardStatus == 1 ? '正常' : '注销',
            }
          })
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
